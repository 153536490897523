<template>
    <div class="loginContainer flex">
        <div class="loginLeft overflow drag">
            <img src="../src/assets/login_bg.png" alt="" class="userSelect loginLeftImg">
            <div class="loginLeftText userSelect">
                <img src="../src/assets/login_logo.png" alt="">
                <p class="loginLeftTitle colorWhite userSelect">让采集更方便<span>更快捷</span></p>
            </div>
        </div>
        <div class="loginRight overflow">
            <img src="../src/assets/closewin.png" alt="" class="closeWin" @click="closeMethod">
            <div class="loginLeftText">
                <div class="loginRightTop flex">
                    <li :class="{ cur: current == index }" @click="navChange(index)" v-for="(item, index) in loginMenu">
                        {{ item }}</li>
                </div>
                <div class="loginForm overflow" v-if="current != 2">
                    <template v-if="current == 1">
                        <li>
                            <input class="loginInput" placeholder="请输入用户名" v-model="params.user_name" />
                        </li>
                        <!-- <li >
                        <input class="loginInput" placeholder="请输入手机号" v-model="params.mobile" />
                    </li> -->

                    </template>
                    <li v-if="current == 0">
                        <input class="loginInput" placeholder="请输入用户名/邮箱" v-model="params.account" />
                    </li>
                    <li>
                        <!-- <el-input placeholder="请输入密码" v-model="params.password" show-password></el-input> -->
                        <input class="loginInput" autocomplete="new-password" placeholder="请输入密码"
                            v-model="params.password" type="password" :readonly='isReadOnly' @focus="readMethod(1)"
                            @blur="readMethod(2)" />
                    </li>
                    <template v-if="current == 1">
                        <li>
                            <input class="loginInput" placeholder="请输入邮箱(用于找回密码)" v-model="params.email" />
                        </li>
                        <li>
                            <input class="loginInput" :placeholder="agentInfo.is_force_set_invitation_code == '1'?'请输入代理商邀请码':'请输入代理商邀请码(非必填)'" v-model="params.invite_code" />
                        </li>
                    </template>
                </div>
                <div class="loginForm overflow forgetForm" v-else>
                    <li>
                        <input class="loginInput" placeholder="请输入邮箱" v-model="emailParams.email" />
                    </li>
                    <li class="forgetInput flex flexSpace">
                        <input class="loginInput" placeholder="请输入验证码" v-model="emailParams.code" />
                        <p class="forgetBtn cursor baseBg colorWhite textCenter" @click="sendMethod">{{ sendText }}
                        </p>
                    </li>
                    <li>
                        <input class="loginInput" autocomplete="new-password" placeholder="请输入新密码"
                            v-model="emailParams.password" type="password" />
                    </li>
                </div>
                <p class="loginRightTitle" v-if="current == 0"><span @click="forgetPwd">忘记密码?</span></p>
                <p class="loginBtn colorWhite userSelect" @click="loginEvent" v-if="current != 2"><i
                        class="el-icon-loading" v-if="isLoading"></i>{{ current == 0 ? '登录' : '注册' }}</p>
                <!-- <p class="loginBtn colorWhite" @click="loginEvent" v-if="current != 2">{{ current == 0 ? '登录' : '注册' }}
                </p> -->
                <p class="loginBtn colorWhite" @click="findEvent" v-else><i class="el-icon-loading"
                        v-if="isLoading"></i>找回密码</p>
            </div>

        </div>
    </div>
</template>
<script>
    import api from "../http/api";
    import { getMac, getOs, closeWin, resizeTo, maxWin, addConsoleTxt, objectMethod } from '../utils/win'
    import { checkOut, getMacMethod } from "../utils/index";
    import { Message, MessageBox } from 'element-ui';
    export default {
        data() {
            return {
                isReadOnly: true,//密码框是否只读
                timerLogin: '',
                timer: null,
                isDisabled: true, //发送按钮是否禁用
                sendText: '发送验证码',//发送
                isLoading: false,//是否加载动画
                params: {
                    user_name: '',
                    password: '',
                    email: '',
                    mobile: '',
                    account: '',//用户名或者邮箱
                },//注册参数
                emailParams: {
                    email: '',
                    code: '',
                    password: '',
                },//忘记密码
                current: 0,//登录导航默认选中账号登录
                loginMenu: ['账号登录', '注册账号'], //登录导航 
                agentInfo: '',//代理信息
            }
        },
        async created() {
            let macResult = await getMac()
            let agentSet = await api.getUpdate({
                call_index: 'AgentSet'
            })
            this.agentInfo = agentSet.ext
            top.window.require && addConsoleTxt(`获取mac：${macResult}`)
            //console.log('macResult',macResult,localStorage.getItem('mac'))
            if (this.current == 0 && localStorage.getItem('userAccount') && localStorage.getItem('mac') && localStorage.getItem('mac') == macResult) {
                let account = localStorage.getItem('userAccount').split('&&');
                this.params.account = account[0]
                this.params.password = account[1]
            } else {
                localStorage.setItem('downAdress', '')
            }


        },
        methods: {

            readMethod(index) {
                this.isReadOnly = index == 1 ? false : true
            },
            //关闭窗口
            closeMethod() {
                top.window.require && closeWin();
            },
            //切换头部并清空数据
            navChange(index) {
                this.current = index;
                this.current != 2 && (this.loginMenu = ['账号登录', '注册账号'])
                this.params = {
                    user_name: '',
                    password: '',
                    email: '',
                    // mobile: '',
                    account: ''
                }
                this.emailParams = {
                    email: '',
                    code: '',
                    password: '',
                }
            },
            //找回密码
            findEvent() {
                let that = this
                if (!that.emailParams.email || !that.emailParams.code || !that.emailParams.password) {
                    Message({
                        message: `请输入${!that.emailParams.email ? '邮箱' : (!that.emailParams.code ? '验证码' : '密码')}~'`,
                        type: 'error'
                    });
                    return;
                }
                if (!checkOut('email', that.emailParams.email)) {
                    Message({
                        message: '请输入正确的邮箱~',
                        type: 'error'
                    });
                    return
                }
                if (that.emailParams.password.length < 6) {
                    Message({
                        message: '密码不能少于6位~',
                        type: 'error'
                    });
                    return
                }
                that.isLoading = true;
                api.findPwd({ ...that.emailParams }).then(res => {
                    console.log('修改密码', res)
                    that.isLoading = false;
                    Message({
                        message: res.code == 200 ? '修改密码成功,请前往登录~' : res.msg,
                        type: res.code == 200 ? 'success' : 'error'
                    });
                    res.code == 200 && setTimeout(() => {
                        that.navChange(0)
                    }, 3000)

                })
            },
            //发送邮箱
            sendMethod() {
                let that = this;
                if (!that.emailParams.email) {
                    Message({
                        message: '请输入邮箱~',
                        type: 'error'
                    });
                    return;
                }
                if (!checkOut('email', that.emailParams.email)) {
                    Message({
                        message: '请输入正确的邮箱~',
                        type: 'error'
                    });
                    return
                }
                if (that.isDisabled) {
                    let time = 60
                    that.isDisabled = false
                    that.timer = setInterval(() => {
                        if (time == 0) {
                            clearInterval(that.timer);
                            that.isDisabled = true;
                            that.sendText = '发送验证码'
                        } else {
                            that.sendText = time + 'S重新发送'
                            time--;
                        }
                    }, 1000)
                    api.sendEmail({ email: that.emailParams.email }).then(res => {
                        console.log('发送邮件res', res)
                        if (res.code == 201) {
                            that.timer && clearInterval(that.timer);
                            that.sendText = '发送验证码';
                            that.isDisabled = true;
                            // return;
                        }
                        Message({
                            message: res.code == 200 ? res.response : res.msg,
                            type: res.code == 200 ? 'success' : 'error'
                        });
                    })
                }
            },
            //忘记密码
            forgetPwd() {
                let that = this;
                that.loginMenu.push('密码找回');
                that.current = 2;
                that.timer && clearInterval(that.timer);
                that.sendText = '发送验证码';
                that.isDisabled = true;
            },
            //登录/注册
            async loginEvent() {
                let that = this;
                console.log(that.current)
                // (that.current == 1 && !that.params.mobile)
                if ((that.current == 0 && !that.params.account) || (that.current == 1 && !that.params.email) || !that.params.password || (that.current == 1 && !that.params.user_name)) {
                    Message({
                        message: `请输入${(that.current == 0 && !that.params.account) ? '用户名/邮箱' : (!that.params.password ? '密码' : (that.current == 1 && !that.params.user_name ? '用户名' : '邮箱'))}~`,
                        type: 'error'
                    });
                    return
                }
                if (that.current == 1) {
                    if (!checkOut('email', that.params.email)) {
                        Message({
                            message: '请输入正确的邮箱~',
                            type: 'error'
                        });
                        return
                    }
                    // let agentSet = await api.getUpdate({
                    //     call_index: 'AgentSet'
                    // })
                    //console.log('代理商配置--->', that.agentInfo)
                    if (that.agentInfo.is_force_set_invitation_code == '1' && !that.params.invite_code) {
                        Message({
                            message: '请输入代理商邀请码~',
                            type: 'error'
                        });
                        return
                    }
                    if (that.params.password.length < 6) {
                        Message({
                            message: '密码不能少于6位~',
                            type: 'error'
                        });
                        return
                    }
                }
                that.isLoading = true;
                let macResult = await getMacMethod()
                let macValue = await getMac()
                console.log('macResult', macValue, macResult)
                localStorage.setItem('mac', macValue)
                // return;
                let result = {
                    aunique_id: macResult ? macResult : '',
                    name: top.window.require ? getOs() : '',
                };
                if (that.current == 0) {
                    result.account = that.params.account
                    result.password = that.params.password
                } else {
                    result = Object.assign(JSON.parse(JSON.stringify(that.params)), result)
                    delete result['account']
                }
                console.log('result', result)
                let res = that.current == 1 ? await api.register(result) : await api.login(result)
                that.isLoading = false;
                if (res.code == 200) {
                    if (that.current == 1) {
                        localStorage.setItem('userAccount', `${that.params.account}&&${that.params.password}`)
                        Message({
                            message: '注册成功,请前往登录~',
                            type: 'success'
                        });
                        setTimeout(() => {
                            that.navChange(0)
                        }, 3000)
                    } else {
                        // console.log('res', res)
                        that.timerLogin && clearTimeout(that.timerLogin)
                        localStorage.setItem('token', res.response)
                        localStorage.setItem('userAccount', `${that.params.account}&&${that.params.password}`)
                        try {
                            top.window.require && addConsoleTxt(`获取屏幕高：${window.screen?.availHeight}`)
                            top.window.require && resizeTo(1280, window.screen?.availHeight < 800 ? window.screen.availHeight : 800)
                        }
                        catch (err) {
                            top.window.require && addConsoleTxt(`ERROR screen：${err}`)
                        }
                        that.$router.push('/index')
                        // that.timerLogin=setTimeout(()=>{
                        //     console.log('hahah')
                        //     that.$router.push('/index')
                        // },200)

                    }
                } else {
                    res.code == 403 ? MessageBox.alert(res.msg, '温馨提示', {
                        confirmButtonText: '确定',
                        callback: action => { }
                    }) : Message({
                        message: res.msg,
                        type: 'error'
                    });
                }
                //console.log('走到这里了？', res)
            },

            //获取mac
            // getMacMethod(){
            //     return new Promise((reslove,reject)=>{
            //         top.window.require?window.getUnionId((id) => {
            //             reslove(id)
            //        // result.aunique_id=id
            //             console.log('获取到的mac',id);
            //         }): reslove('')
            //     })
            // }
        },
    }
</script>
<style lang="less" scoped>
    .loginContainer {
        width: 100%;
        height: 100vh;

        .loginLeft {
            width: 50%;
            height: 100%;

            .loginLeftImg {
                width: 100%;
                height: 100%;
                /* object-fit: cover; */
            }

            .loginLeftText {
                width: 100%;
                position: absolute;
                top: 40%;
                left: 40%;
                transform: translate(-40%, -40%);

                img {
                    width: 50%;
                    margin: auto;
                }
            }

            .loginLeftTitle {
                font-size: 20px;
                text-align: center;
                margin-top: 40px;

                span {
                    margin-left: 15px;
                }
            }
        }

        .loginRight {
            width: 50%;
            height: 100%;

            .loginLeftText {
                width: 350px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            .loginRightTop {
                width: 100%;

                li {
                    width: 50%;
                    text-align: center;
                    font-size: 20px;
                    color: #9EA2AC;
                    position: relative;
                    padding-bottom: 15px;
                    cursor: pointer;

                    &:after {
                        content: '';
                        position: absolute;
                        width: 0px;
                        height: 5px;
                        background: #0B41D7;
                        border-radius: 2px;
                        bottom: 0;
                        transform: translateX(-50%);
                        left: 50%;

                    }
                }

                .cur {
                    color: #000;

                    &:after {
                        width: 36px;
                    }
                }
            }

            .loginForm {
                width: 100%;
                margin-top: 30px;

                li {
                    width: 100%;
                    background: #F2F4F9;
                    border-radius: 2px;
                    margin: 17px 0;

                    .loginInput {
                        width: 100%;
                        border: 0;
                        background: transparent;
                        line-height: 45px;
                        padding: 0 29px;
                        font-size: 16px;
                    }
                }
            }

            .forgetForm {
                .forgetInput {
                    padding-right: 10px;
                    background: transparent;

                    .loginInput {
                        width: 60%;
                        background: #F2F4F9;
                        box-sizing: border-box;
                    }

                    .forgetBtn {
                        width: 35%;
                        line-height: 40px;
                        font-size: 14px;
                    }
                }
            }

            .loginRightTitle {
                color: #9EA2AC;
                font-size: 16px;
                text-align: right;
                cursor: pointer;

            }

            .loginBtn {
                color: #fff !important;
                width: 100%;
                line-height: 45px;
                border-radius: 2px;
                background: #0B41D7;
                text-align: center;
                font-size: 18px;
                margin-top: 40px;
                cursor: pointer;

                i {
                    margin-right: 5px;
                }
            }
        }

        /* 关闭按钮    */
        .closeWin {
            width: 20px;
            position: absolute;
            top: 5px;
            right: 10px;
            z-index: 10;
        }

    }
</style>